<template>
    <div
        class="flex items-center justify-center space-x-4 px-2 pb-4 pt-10 text-sm"
    >
        <span>{{ copyright }}</span>
        <NuxtLink
            :to="termsLink"
            target="_blank"
            class="text-blue-500 hover:underline"
        >
            {{ t('terms') }}
        </NuxtLink>
        <NuxtLink
            :to="privacyLink"
            target="_blank"
            class="text-blue-500 hover:underline"
        >
            {{ t('privacy') }}
        </NuxtLink>
    </div>
</template>

<script setup lang="ts">
import { Locale } from '@prisma/client'

const runtimeConfig = useRuntimeConfig()
const { t, locale } = useI18n()

const copyright = computed(() => {
    const year = new Date().getFullYear()
    const company = locale.value === Locale.BG ? 'еФирма ООД' : 'eFirma Ltd.'

    return `© ${year} ${company}`
})

const termsLink = computed(
    () => runtimeConfig.public.EFIRMA_WEBSITE_URL + '/terms',
)
const privacyLink = computed(
    () => runtimeConfig.public.EFIRMA_WEBSITE_URL + '/privacy',
)
</script>
